<template>
	<app>
		<flow-node :node="nodeList" :current="currentNode" :reject="rejectNode"></flow-node>
		<c-detail>
			<c-detail-item label="合同标题">
				{{detailInfo.name}}
			</c-detail-item>
			<c-detail-item label="合同编号">
				{{detailInfo.code}}
			</c-detail-item>
			<c-detail-item label="合同状态">
				<span v-if="contractState[detailInfo.qs_state]" :style="{'color': contractState[detailInfo.qs_state].color}">{{contractState[detailInfo.qs_state].name}}</span>
			</c-detail-item>
			<c-detail-item label="所属项目名称">
				{{detailInfo.project_name}}
			</c-detail-item>
			<c-detail-item label="甲方">
				{{detailInfo.yzdw_name}}
			</c-detail-item>
			<c-detail-item label="乙方">
				{{detailInfo.zbqy_name}}
			</c-detail-item>
			<c-detail-item label="签约截止日">
				{{detailInfo.qy_last_date}}
			</c-detail-item>
			<c-detail-item label="乙方经办人">
				{{detailInfo.zbqy_user_realname}}<span v-if="detailInfo.zbqy_user_phone">（{{detailInfo.zbqy_user_phone}}）</span>
			</c-detail-item>
			<c-detail-item label="创建时间">
				{{detailInfo.create_date}}
			</c-detail-item>
			<c-detail-item label="乙方电子邮箱">
				{{detailInfo.zb_money}}
			</c-detail-item>
			<c-detail-item label="合同源文件">
				<c-file v-if="detailInfo.file_path" :src="detailInfo.file_path"></c-file>
			</c-detail-item>
			<c-detail-item v-if="detailInfo.yz_qs_file_path || detailInfo.qy_qs_file_path" label="签署后文件">
				<c-file v-if="detailInfo.curr_file_path" :src="detailInfo.curr_file_path"></c-file>
			</c-detail-item>
		</c-detail>
		
		<c-detail title="签署流程">
			<timeline :data="timeline"></timeline>
		</c-detail>
			
		<button-list-m v-if="approveButton || signButton" slot="foot">
			<c-button color="green" size="m" @click="$refs.sms.open(approveButton ? userInfo.fgld_phone : userInfo.seal_phone)">{{approveButton ? '审批通过' : '签署'}}</c-button>
			<c-button color="red" size="m" @click="$refs.approve.reject()">驳回</c-button>
		</button-list-m>
		
		<approve ref="approve" @resolve="approveHandle"></approve>
		
		<smscode ref="sms" :business="id" :scene="approveButton ? 1 : 11" :buttonName="approveButton ? '确认通过' : '确认并签署'" @submit="approveHandle(arguments[0], '', false, arguments[1])"></smscode>
	</app>
</template>

<script>
	import flowNode from '@/components/module/flow_node.vue'
	import timeline from '@/components/module/timeline.vue'
	import approve from '@/components/module/approve.vue'
	import smscode from '@/components/module/smscode.vue'
	import {mapState, mapGetters} from 'vuex'
	
	export default {
		components: {
			flowNode,
			timeline,
			approve,
			smscode
		},
		
		props: {
			id: String
		},
		
		data() {
			return {
				nodeList: [{
					name: '创建合同'
				}, {
					name: '待业主领导审批'
				}, {
					name: '待业主签署'
				}, {
					name: '待企业签署'
				}, {
					name: '合同签署完结'
				}],
				detailInfo: {},
				timeline: []
			}
		},
		
		computed: {
			...mapState(['userInfo']),
			...mapGetters(['contractState']),
			
			currentNode() {
				return this.detailInfo.qs_state + 2;
			},
			
			rejectNode() {
				return this.detailInfo.qs_state == 4 ? {
					41: 2,
					42: 3,
					7: 4
				}[this.detailInfo.reject_user_type] : 0;
			},
			
			approveButton() {
				return this.detailInfo.qs_state == 0;
			},
			
			signButton() {
				return this.detailInfo.qs_state == 1;
			}
		},
		
		mounted() {
			this.updateDetail();
			this.$store.dispatch('updateUserInfo');
		},
		
		methods: {
			updateDetail() {
				this.request({
					url: '/project/ht/detail',
					data: {
						ht_id: this.id
					},
					success: data => {
						this.detailInfo = data.htInfo;
						this.timeline = Array.from(data.logList, item => {
							return {
								date: item.create_date,
								content: `[${item.create_user_name}] ${item.remark}`
							}
						});
					}
				});
			},
			
			approveHandle(code, remark, stop, next) {
				stop && stop();
				
				this.request({
					url: this.approveButton ? '/project/ht/check_yzld' : '/project/ht/check_qs',
					data: {
						ht_id: this.id,
						qs_state: code ? 2 : 4,
						reject_remark: remark,
						verify_code: code
					},
					loading: code && this.signButton ? '请稍等，正在进行在线签章操作...' : true,
					success: data => {
						next();
						this.$message({
							text: '审批成功',
							resolve: () => {
								this.updateDetail();
							}
						});
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>